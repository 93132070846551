<template>
	<div>
		<div class="grid-container">
			<div class="grid-x align-center" style="position:relative;">
			<a v-if="masterfolder == 189 || masterfolder == 397" class="homelink" href="/">Main Site</a>
			<ul class="menu topmenu align-center">
				<li v-for="(menuitem, index) in menuitems">
					<a :href="menuitem.url" @mouseover="showmega($event, index)" @mouseout="cleartimeout" @click="showmega($event, index)" v-bind:class="{current : menuitem.current }">{{ menuitem.title }}</a>
				</li>
			</ul>
			</div>
		</div>
		<div class="grid-container full">
			<mega-menu-dropdown v-if="activelink != null" v-model="menuitems[activelink]" v-on:closemenu="hidemega($event)"  @mouseover="cancelhide($event)" @mouseleave="hidemegadelayed($event)"></mega-menu-dropdown>
		</div>
	</div>
</template>
<script>
	import megaMenuDropdown from './mega-menu-dropdown';

	export default {
        props: ['currentfolder', 'masterfolder'],
		components: {
			megaMenuDropdown
		},
        data: function () {
            return {
                menuitems : [],
                activelink : null,
                menutimer: null,
                timeout: null
            }
        },
        methods: {
            getmenu: function () {
                var vm = this;
                RendiliCore.Json.PostJsonData({
                url: '/alternative/megamenu/getmenu',
                data: {
                    masterfolder : this.masterfolder,
                    currentfolder: this.currentfolder,
                }
                })
                .then(function (data) {
                    vm.menuitems = data.items;
                });
            },
            showmega: function (event, index) {
                var vm = this;
                if (this.timeout != null) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                if(this.menuitems[index].children.length > 0) {
                    event.preventDefault();
                    if (this.menutimer == null) {
                        this.menutimer = setTimeout(function() {
                            vm.activelink=index;
                        }, 250);
                    }
                } else {
                    this.activelink = null;
                }
            },
            cleartimeout: function() {
                if (this.menutimer != null) {
                    clearTimeout(this.menutimer);
                    this.menutimer = null;
                }
            },
            hidemega : function() {
                this.activelink = null;
                this.timeout = null;
            },
            hidemegadelayed : function() {
                var self = this;
                this.timeout = setTimeout(function() { self.hidemega() }, 500);
            },
            cancelhide : function() {
                if (this.timeout != null) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
            }
        },
        created: function () {
            //this.getmenu();
            this.menuitems = megamenu_data;
        },
	}
</script>